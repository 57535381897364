<template>
    <div class="text-title-icon-list" :class="[blok.Style, MobileContentAlign]" :style="getStyles">
        <h5 v-if="blok.Title" class="title">{{ blok.Title }}</h5>
        <div v-for="item in textTitleIcons" :key="item.item._uid" class="item" :class="item.item.mobile_style">
            <div
                v-if="item.item.icon.filename"
                class="item-icon"
                :class="item.item.icon_size"
                :style="`${blok.VerticalAlignIcons ? `align-self: ${blok.VerticalAlignIcons};` : ''}`"
            >
                <div class="icon-wrap">
                    <NuxtImg
                        v-if="item.item?.icon?.filename"
                        :src="item.item?.icon?.filename"
                        :title="item.item.icon.title"
                        :alt="item.item.icon.alt"
                        provider="storyblok"
                        format="webp"
                        loading="lazy"
                        densities="x1"
                    />
                </div>
            </div>
            <div class="item-body">
                <h5 v-if="item.item.title" class="item-title" :style="getTitleStyle(item)">
                    {{ item.item.title }}
                </h5>
                <p v-if="item.item.text" class="item-text" :style="getTextStyle(item)">
                    {{ item.item.text }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ITextTitleIconList } from '~ui/types/components/TextTitleIconList';
import type { ITextTitleIcon } from '~ui/types/components/TextTitleIcon';

interface IItem {
    item: ITextTitleIcon;
    imageSize: {
        viewport: string | null;
        size: {
            width: number;
            height: number;
        };
    }[];
}

const props = defineProps<{ blok: ITextTitleIconList }>();
const MobileContentAlign = computed(() => {
    return {
        'mobile left-horizontal': props.blok.MobileContentAlign === 'left',
        'mobile center-horizontal': props.blok.MobileContentAlign === 'center',
        'mobile right-horizontal': props.blok.MobileContentAlign === 'right',
    };
});
const getTitleStyle = (item: IItem) => {
    return `${item.item.title_font_weight ? `font-weight: ${item.item.title_font_weight};` : ''}
            ${Number(item.item.title_font_size) ? `font-size: ${item.item.title_font_size}rem; line-height: normal;` : ''}
            ${item.item.title_color?.color ? `color: ${item.item.title_color?.color}; line-height: normal;` : ''}`;
};

const getStyles = computed(() => {
    return `${props.blok.paddingLeft ? `padding-left: ${props.blok.paddingLeft}px;` : ''}
            ${props.blok.paddingRight ? `padding-right: ${props.blok.paddingRight}px;` : ''}`;
});

const getTextStyle = (item: IItem) => {
    return `${Number(item.item.text_font_size) ? `font-size: ${item.item.text_font_size}rem; line-height: unset;` : ''}
            text-align: ${item.item?.TextAlign || null}`;
};
const textTitleIcons = computed<IItem[]>(() =>
    props.blok.items.map((item) => {
        return {
            item: item,
            imageSize: [
                {
                    viewport: null,
                    size: {
                        width: item.icon_size === 'large' ? 75 : 35,
                        height: 0,
                    },
                },
            ],
        };
    })
);
</script>

<style scoped lang="postcss">
.text-title-icon-list {
    .item {
        &.mobile-stack {
            @apply max-md:flex max-md:flex-col;

            .item-icon {
                @apply max-md:justify-center;
            }

            .item-body {
                @apply max-md:text-center max-md:mt-4;
            }
        }
    }

    &.white-plate-items {
        @apply mt-0 mb-7 mx-0 pl-0 md:pr-[17px] py-0 gap-y-0;
        list-style: none;
        counter-reset: list;

        .item {
            @apply list-item relative p-0 my-0 mb-3;

            &:before {
                @apply items-center bg-white text-[#055988] flex text-[32px] font-bold h-[51px] justify-center leading-[35px] mt-[-27px] absolute text-center w-[51px] mb-0 mx-0 rounded-[100%] border-[3px] border-solid border-[#055988] left-[11px] top-2/4;
                counter-increment: list;
                content: counter(list, decimal);
            }
        }

        .item-body {
            @apply w-full min-h-[75px] bg-white flex shadow-[0_0_8px_0_#dc9229] pl-[83px] pr-[100px] py-2;

            .item-text {
                @apply text-base font-normal leading-[19px] text-[#666];
            }
        }
        .item-icon {
            @apply hidden;
        }
    }

    @media screen and (max-width: 768px) {
        &.mobile {
            .item {
                @apply flex flex-col gap-y-[21px];

                .item-icon {
                    @apply mr-0 self-center;

                    .icon-wrap {
                        @apply flex justify-center;
                    }
                }
            }

            .item-body {
                .item-text,
                .item-title {
                    @apply text-center;
                }

                .item-text {
                    @apply mt-[10px];
                }
            }

            &.left-horizontal {
                .item {
                    .item-icon {
                        @apply self-start;
                    }
                }

                .item-body {
                    .item-text,
                    .item-title {
                        @apply text-left;
                    }
                }
            }

            &.right-horizontal {
                .item {
                    .item-icon {
                        @apply self-end;
                    }
                }

                .item-body {
                    .item-text,
                    .item-title {
                        @apply text-right;
                    }
                }
            }
        }
    }
}
</style>
